// CSI_DatePicker
(function($){
  jQuery.fn.csiDatePicker = function(options) {

    var settings = jQuery.extend({
      format: 'dd.mm.yyyy',
      autoclose : true,
      language : 'it-CH'
    }, options);

    return this.each(function() {
      var $this = $(this);
      $this.datepicker(settings);
    });
  };
})(jQuery);

// CSI_Datatables
(function($){
    jQuery.fn.csiDataTable = function(options) {
      var settings = jQuery.extend({
        aLengthMenu : [[5, 10, 25, 50, -1], [5, 10, 25, 50, "Tutti"]],
        buttons : [],
        dom: "<'row'B><'row appDTHeader'<'hidden-xs'l><'hidden-xs'f>>" + "<'row appDTBody'<'trClass'tr>>" + "<'row appDTFooter'ip>",
        oLanguage : {
          "oAria": {
              "sSortAscending":  ": Attiva per ordinare la colonna in ordine crescente",
              "sSortDescending": ": Attiva per ordinare la colonna in ordine decrescente"
          },
          "oPaginate": {
              "sFirst": "<span class='fa fa-angle-double-left'></span>",
              "sLast": "<span class='fa fa-angle-double-right'></span>",
							"sPrevious": "<span class='fa fa-angle-left'></span>",
              "sNext": "<span class='fa fa-angle-right'></span>",
              "sPage": "Pagina"
          },
          "sEmptyTable": "<span class='consult'>Non vi sono elementi con i parametri selezionati</span>",
          "sInfoPostFix": "",
          "sInfoThousands": ",",
          "sInfo": "<span class='consult'>_START_-_END_ di <b>_TOTAL_</b></span>",
          "sInfoEmpty": "<span class='consult'>Nessun elemento trovato</span>",
          "sInfoFiltered": "<span class='consult'> (filtrati su un totale di _MAX_ elementi)</span>",
          "sLengthMenu": "Risultati pagina: _MENU_",
          "sPaginationType": "full_numbers",
          "sProcessing": "Elaborazione...",
          "sSearch": "<span class='filter'>Filtro: </span><span class='box'> _INPUT_ </span>",
          "sZeroRecords": "<span class='consult'>Non vi sono elementi con i parametri selezionati</span>"
        }
      }, options);

      return this.each(function() {
        var $this = $(this);
        $this.dataTable(settings);
      });
  };
})(jQuery);
